<template>
    <div class="news_detail">
        <div class="container">
            <div class="header">
                <h3 class="title">
                    {{ news.title }}
                </h3>
                <p class="time">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                    </svg>
                    {{dateTime(news.created_at)}}
                </p>
            </div>
            <div class="content">
                <figure class="image">
                    <img :src="imageUrl+'berita/'+news.image" alt="">
                </figure>
                <div class="text" v-html="news.desc"></div>
            </div>

            <div class="other_news">
                <h4>Baca Juga</h4>
                <hr>

                <div class="row">
                    <template v-for="(beritaLain, index) in newsAll" :key="index">
                    <div class="col-lg-4 col-6" v-if="beritaLain.id !== news.id">
                        <Berita v-bind:beritaLain="beritaLain" @click="load()"/>
                    </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Footer from '@/components/Footer.vue'
import { useRoute } from 'vue-router'
import Berita from '@/components/Berita.vue'

import axios from 'axios'
import { onMounted, ref } from 'vue'

import moment from 'moment'

export default {
    name: 'NewsDetail',
    components: {
        Footer,
        Berita,
    },
    methods: {
        load(load){
            this.load = load;
			location.reload()
        }
    },
    setup(){
        const news = ref([])
        const newsAll = ref([])
        const route = useRoute()

        onMounted(() => {
            
            axios.get(`getNews/${route.params.id}`)
			.then((result) => {
                console.log('News Detail: ', result.data.news)
                news.value = result.data.news
			}).catch((err) => {
				console.log(err.response)
			})
            axios.get(`getNews`)
			.then((result) => {
                console.log('News All: ', result.data)
                newsAll.value = result.data
			}).catch((err) => {
				console.log(err.response)
			})
        })

        function dateTime(value) {
			return moment(value).format("DD MMMM YYYY hh:mm:ss A");
		}

        return{
            news,
            newsAll,
            route,
            dateTime,
        }
    }
}
</script>

<style lang="scss" scoped>
.news_detail{
    .header{
        padding: 50px 0 30px;
        .title{
            color: $blue;
            font-weight: 700;
            text-align: center;
        }
        .time{
			font-size: 10pt;
			color: $lightGrey;
            display: flex;
            align-items: center;
            justify-content: center;
			svg{
				width: 12px;
				height: 12px;
			}
		}
    }

    .content{
        margin: 0 0 50px;
        .image{
            width: 100%;
            img{
                width: 100%;
            }
        }
        .text{
            padding: 20px;
            p{
                color: $grey;
                text-align: justify;
            }
        }
    }

    .other_news{
        h4{
            color: $blue;
            font-weight: 700;
        }
        hr{
            background: $grey;
        }

        .card{
			border: none;
			border-radius: 0;
			box-shadow: 0 3px 30px rgba(0, 0, 0, 0.05);
            margin-bottom: 40px;
            height: 300px;
			.card-img-top{
				width: 100%;
				height: 180px;
				margin-bottom: 0;
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.card-text{
				font-weight: 700;
				margin-bottom: 0;
                color: $darkGrey;
			}
			.time{
				font-size: 8pt;
				color: $lightGrey;
				svg{
					width: 12px;
					height: 12px;
				}
			}
            .card-footer{
                background: none;
                border: none;
                .btn__primary{
                    font-size: 10pt;
                    float: right;
                    padding: 10px 20px 10px 40px;
                    svg{
                        margin-left: 20px;
                    }
                }
            }
		}
    }
    @media (max-width: 767.98px)
    {
        .other_news{
            .card{
                height: 240px;
                .card-img-top{
                    height: 120px;
                }
                .time{
                    font-size: 6pt;
                    color: $lightGrey;
                    display: flex;
                    align-items: flex-start;
                    svg{
                        margin-right: 3px;
                        margin-bottom: 10px;
                        width: 10px;
                        height: 10px;
                    }
                }
                .card-text{
                    font-size: 9pt;
                }
                .card-footer{
                    .btn__primary{
                        font-size: 8pt;
                        float: right;
                        padding: 10px;
                        svg{
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}
</style>