<template>
    <div class="client_detail">
        <figure class="image">
            <img :src="imageUrl+'klien/'+client.image" alt="">
        </figure>
        <div class="container">
            <h3 class="title">
                {{ client.name }}
            </h3>

            <div class="text" v-html="client.desc"></div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Footer from '@/components/Footer.vue'
import { useRoute } from 'vue-router'

import axios from 'axios'
import { onMounted, ref } from 'vue'

export default {
    name: 'Client',
    components: {
        Footer,
    },
    setup(){
        const client = ref([])
        const route = useRoute()

        onMounted(() => {
            axios.get(`getClient/${route.params.id}`)
			.then((result) => {
                console.log('Clients Detail : ', result.data)
                client.value = result.data.client
			}).catch((err) => {
				console.log(err.response)
			})
        })

        return{
            client,
            route,
        }
    }
}
</script>

<style lang="scss" scoped>
.client_detail{
    .image{
        width: 100%;
        height: 400px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .title{
        color: $blue;
        font-weight: 700;
        text-align: left;
        padding: 20px 20px 0;
    }
    .text{
        padding: 20px;
        p{
            color: $grey;
            text-align: justify;
        }
    }
}
</style>