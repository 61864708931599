<template>
    <div class="client">
        <div class="container">
            <h2 class="title">Klien Kami</h2>

            <div class="list">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-12" v-for="list in clients" :key="list">
                        <div class="card">
                            <figure class="card-img-top">
                                <img :src="imageUrl+'klien/'+list.image" alt="...">
                            </figure>
                            <div class="card-body">
                                <router-link :to="'/client/detail/'+list.id" class="card-text text-center">
                                    {{list.name}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Footer from '@/components/Footer.vue'

import axios from 'axios'
import { onMounted, ref } from 'vue'

export default {
    name: 'Client',
    components: {
        Footer,
    },
    setup(){
        const clients = ref([])

        onMounted(() => {
            axios.get(`getClient`)
			.then((result) => {
                console.log('Clients : ', result.data)
                clients.value = result.data
			}).catch((err) => {
				console.log(err.response)
			})
        })

        return{
            clients,
        }
    }
}
</script>

<style lang="scss" scoped>
.client{
    .title{
        color: $blue;
        text-transform: uppercase;
        font-weight: 700;
        margin: 40px;
        text-align: center;
    }

    .list{
        .card{
			border: none;
			border-radius: 0;
			box-shadow: 0 3px 30px rgba(0, 0, 0, 0.05);
            border: 1px solid $blue;
            margin: 40px 0;
            height: 260px;
            transition: all ease-in-out 0.3s;
			.card-img-top{
				width: 90%;
                display: block;
                margin: -40px auto 0;
				height: 200px;
                clip-path: inset(0 0 0 0);
				img{
                    width: 100%;
					height: 100%;
					object-fit: cover;
                    transition: all ease-in-out 0.3s;
				}
                &:hover{
                    img{
                        transform: scale(1.1);
                    }
                }
			}
			.card-body{
                .card-text{
                    font-weight: 700;
                    margin-bottom: 0;
                    font-size: 11pt;
                    padding: 0 10px;
                    color: $blue;
                    text-align: center !important;
                    display: flex;
                    justify-content: center;
                    text-decoration: none;
                    transition: all ease-in-out 0.3s;
                }
            }

            &:hover{
                background: $blue;
                .card-body{
                    .card-text{
                        color: white;
                    }
                }
            }
		}
    }
}
</style>