<template>
    <div class="training">
        <div class="head">
            <img src="../assets/img/icons/circle-two2.svg" alt="" class="circle">
			<img src="../assets/img/icons/dot-vertical-22.svg" alt="" class="dot_v">
			<img src="../assets/img/icons/rect-layer2.svg" alt="" class="rect">
			<img src="../assets/img/icons/dot-vertical-22.svg" alt="" class="dot_v2">
            <div class="container">
                <div class="inner">
                    <h4 class="subtitle">Produk Layanan</h4>
                    <h1 class="title">
                        Konsultasi & Pelatihan <br>
                        Sistem Manajemen
                    </h1>
                </div>
            </div>
        </div>

        <div class="product">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-12" v-for="item in products" :key="item">
                        <div class="card">
                            <figure class="card-img-top">
                                <img :src="imageUrl+'jenis/'+item.image" alt="...">
                            </figure>
                            <div class="card-body">
                                <p class="card-text" v-if="item.name.length>=50">
                                    {{item.name.substring(0,50)+'...'}}
                                </p>
                                <p class="card-text" v-else>
                                    {{item.name}}
                                </p>
                            </div>
                            <div class="card-footer">
                                <div class="button">
                                    <router-link :to="'/training-and-consultation/'+item.id" class="btn btn__primary">
                                        Lihat
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                                            <g id="feather-arrow-right" transform="translate(-6 -6)">
                                                <path id="Union_1" data-name="Union 1" d="M4.558,9.817a.626.626,0,0,1,0-.884L7.867,5.625H.625a.625.625,0,0,1,0-1.25H7.866L4.558,1.067A.625.625,0,0,1,5.442.183L9.817,4.558l0,0,0,0,.008.008v0a.624.624,0,0,1,.161.5v.014l0,.01v0l0,.012v0l0,.013h0c0,.01,0,.019-.007.029h0l0,.014h0l0,.013v0l0,.012v0l0,.01,0,.005,0,.008,0,.009,0,0-.005.012v0a.616.616,0,0,1-.078.128l-.005.006,0,.005L9.852,5.4l0,0-.008.009,0,0-.009.01h0l-.011.011L5.442,9.817a.626.626,0,0,1-.884,0Z" transform="translate(6 6)" fill="#223975"/>
                                            </g>
                                        </svg>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Footer from '@/components/Footer.vue'

import axios from 'axios'
import { onMounted, ref } from 'vue'

export default {
    name: 'Training',
    components: {
        Footer,
    },
    setup(){
        const products = ref([])

        onMounted(() => {
            axios.get('getType')
			.then((result) => {
                console.log('Produk : ', result.data)
                products.value = result.data
			}).catch((err) => {
				console.log(err.response)
			})
        })

        return{
            products,
        }
    }
}
</script>

<style lang="scss" scoped>
.training{
    .head{
        background: $blue;
		position: relative;
		height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        .circle{
			position: absolute;
			top: 0;
			left: 0;
			width: 400px;
			height: auto;
			z-index: 2;
            opacity: 0.06;
		}
		.dot_v{
			position: absolute;
			top: 20px;
			left: 20px;
			width: 90px;
			height: auto;
			z-index: 2;
            opacity: 0.06;
		}
		.dot_v2{
			position: absolute;
			bottom: 20px;
			right: 20px;
			width: 90px;
			height: auto;
			z-index: 2;
            opacity: 0.06;
		}
		.rect{
			position: absolute;
			bottom: 0;
			right: 0;
			width: 420px;
			height: auto;
			z-index: 1;
            opacity: 0.06;
		}
        .inner{
            .subtitle{
                color: white;
                text-align: center;
                font-weight: 700;
                text-transform: uppercase;
                font-size: 14pt;
            }
            .title{
                color: white;
                text-align: center;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
    }

    .product{
        padding: 40px 0;
        .card{
			border: none;
			border-radius: 0;
			box-shadow: 0 3px 30px rgba(0, 0, 0, 0.05);
            border: 1px solid $blue;
            margin: 40px 0;
            height: 280px;
            transition: all ease-in-out 0.3s;
			.card-img-top{
				width: 90%;
                display: block;
                margin: -40px auto 0;
				height: 150px;
                clip-path: inset(0 0 0 0);
				img{
                    width: 100%;
					height: 100%;
					object-fit: cover;
                    transition: all ease-in-out 0.3s;
				}
                &:hover{
                    img{
                        transform: scale(1.1);
                    }
                }
			}
			.card-body{
                .card-text{
                    font-weight: 700;
                    margin-bottom: 0;
                    font-size: 11pt;
                    padding: 0 10px;
                    color: $blue;
                    text-align: center;
                    transition: all ease-in-out 0.3s;
                }
            }
            .card-footer{
                background: transparent;
                border: 0;
                .button{
                    text-align: center;
                    .btn__primary{
                        font-size: 10pt;
                        padding: 10px 20px 10px 40px;
                        svg{
                            margin-left: 20px;
                        }
                    }
                }
            }

            &:hover{
                background: $blue;
                .card-body{
                    .card-text{
                        color: white;
                    }
                }
                .card-footer{
                    .button{
                        .btn__primary{
                            font-size: 10pt;
                            padding: 10px 20px 10px 40px;
                            background: white;
                            svg{
                                margin-left: 20px;
                            }
                        }
                    }
                }
            }
		}
    }
    @media (max-width: 767.98px){
        .product{
            margin: 0 10%;
        }
    }
}
</style>