import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import Vuelidate from 'vuelidate'


const app = createApp(App)
import axios from 'axios'

// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/swiper-bundle.css'

import VueSplide from '@splidejs/vue-splide'

// import './assets/scss/colors.css'

// ==== Endpoint Staging BIT House ====
axios.defaults.baseURL = 'https://admin.bintangmasconsultant.com/api/'
// ==== Global Image Url Staging Gama BIT House
app.config.globalProperties.imageUrl = 'https://admin.bintangmasconsultant.com/img/';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Method'] = 'GET', 'POST', 'OPTIONS', 'PUT', 'DELETE';
axios.defaults.headers.common.Accept = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'

app.use(VueSplide).use(Vuelidate).use(router).mount('#app')
