<template>
	<div class="home">
		<div class="hero">
			<div class="hero_title">
				<div class="box">
					<h2 class="title">
						Provider Of Consulting & <br>
						Training Management System
					</h2>
					<router-link :to="{ name: 'training' }" class="btn btn__primary">Selengkapnya</router-link>
				</div>
			</div>
			<div class="hero_slider">
				<swiper class="slider" :autoplay="{ delay: 2500, disableOnInteraction: true }" :navigation="{ nextEl: '.b-next', prevEl: '.b-prev', }" :pagination="{ dynamicBullets: true, clickable: true }">
					<swiper-slide v-if="slider.image1 == null" class="noSlider">
						<!-- <div class="swiper-slide">
							<img :src="imageUrl+'slider/'+slider.image1" alt="">
						</div> -->
					</swiper-slide>
					<swiper-slide v-else>
						<div class="swiper-slide">
							<img :src="imageUrl+'slider/'+slider.image1" alt="">
						</div>
					</swiper-slide>
					
					<swiper-slide v-if="slider.image2 == null" class="noSlider">
						<!-- <div class="swiper-slide">
							<img :src="imageUrl+'slider/'+slider.image2" alt="">
						</div> -->
					</swiper-slide>
					<swiper-slide v-else>
						<div class="swiper-slide">
							<img :src="imageUrl+'slider/'+slider.image2" alt="">
						</div>
					</swiper-slide>

					<swiper-slide v-if="slider.image3 == null" class="noSlider">
						<!-- <div class="swiper-slide">
							<img :src="imageUrl+'slider/'+slider.image3" alt="">
						</div> -->
					</swiper-slide>
					<swiper-slide v-else>
						<div class="swiper-slide">
							<img :src="imageUrl+'slider/'+slider.image3" alt="">
						</div>
					</swiper-slide>

					<swiper-slide v-if="slider.image4 == null" class="noSlider">
						<!-- <div class="swiper-slide">
							<img :src="imageUrl+'slider/'+slider.image4" alt="">
						</div> -->
					</swiper-slide>
					<swiper-slide v-else>
						<div class="swiper-slide">
							<img :src="imageUrl+'slider/'+slider.image4" alt="">
						</div>
					</swiper-slide>

					<swiper-slide v-if="slider.image5 == null" class="noSlider">
						<!-- <div class="swiper-slide">
							<img :src="imageUrl+'slider/'+slider.image5" alt="">
						</div> -->
					</swiper-slide>
					<swiper-slide v-else>
						<div class="swiper-slide">
							<img :src="imageUrl+'slider/'+slider.image5" alt="">
						</div>
					</swiper-slide>
					<div class="swiper-nav swiper-nav-next b-next">
						<img src="../assets/img/icons/arrow-next.svg" alt="">
					</div>
					<div class="swiper-nav swiper-nav-prev b-prev">
						<img src="../assets/img/icons/arrow-prev.svg" alt="">
					</div>
				</swiper>
			</div>
		</div>

		<div class="intro">
			<div class="container">
				<h2 class="title">{{ intro.title }}</h2>
				<div class="row">
					<div class="col-lg-6">
						<figure class="image">
							<img :src="imageUrl+'tentang2/'+intro.image" alt="">
						</figure>
					</div>
					<div class="col-lg-6">
						<div class="content" v-html="intro.desc">
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="about">
			<img src="../assets/img/icons/circle-two.svg" alt="" class="circle">
			<img src="../assets/img/icons/dot-vertical-2.svg" alt="" class="dot_v">
			<img src="../assets/img/icons/rect-layer.svg" alt="" class="rect">
			<img src="../assets/img/icons/dot-vertical-2.svg" alt="" class="dot_v2">
		</div>
		<div class="about_content">
			<div class="container">
				<div class="row">
					<div class="col-lg-10 col-md-12 mx-auto">
						<div class="box">
							<h2 class="title">{{about.title}}</h2>
							<p class="desc" v-html="about.desc"></p>

							<router-link to="/about" class="btn btn__primary">Selengkapnya</router-link>

							<img src="../assets/img/icons/dot-vertical-2.svg" alt="" class="dot_v_box">
							<img src="../assets/img/icons/dot-horizontal.svg" alt="" class="dot_h">
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="news">
			<div class="container">
				<h2 class="title">Berita Terupdate</h2>
			</div>

			<Splide :options="newsOptions">
				<SplideSlide v-for="item in news.slice(0, 10)" :key="item">
					<div class="card">
						<figure class="card-img-top">
							<img :src="imageUrl+'berita/'+item.image" alt="...">
						</figure>
						<div class="card-body">
							<p class="card-text" v-if="item.title.length>=60">{{item.title.substring(0,60)+'...'}}</p>
							<p class="card-text" v-else>{{item.title}} <br><span style="visibility: hidden;">.</span></p>
							<small class="time">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
									<path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
									<path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
								</svg>
								{{dateTime(item.created_at)}}
							</small>
							<router-link :to="'/news/detail/'+item.id" class="btn btn__primary">
								Lihat
								<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
									<g id="feather-arrow-right" transform="translate(-6 -6)">
										<path id="Union_1" data-name="Union 1" d="M4.558,9.817a.626.626,0,0,1,0-.884L7.867,5.625H.625a.625.625,0,0,1,0-1.25H7.866L4.558,1.067A.625.625,0,0,1,5.442.183L9.817,4.558l0,0,0,0,.008.008v0a.624.624,0,0,1,.161.5v.014l0,.01v0l0,.012v0l0,.013h0c0,.01,0,.019-.007.029h0l0,.014h0l0,.013v0l0,.012v0l0,.01,0,.005,0,.008,0,.009,0,0-.005.012v0a.616.616,0,0,1-.078.128l-.005.006,0,.005L9.852,5.4l0,0-.008.009,0,0-.009.01h0l-.011.011L5.442,9.817a.626.626,0,0,1-.884,0Z" transform="translate(6 6)" fill="#223975"/>
									</g>
								</svg>
							</router-link>
						</div>
					</div>
				</SplideSlide>
			</Splide>
		</div>
	</div>
	<Footer></Footer>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import { onMounted, ref } from 'vue'

import Footer from '@/components/Footer.vue'
import SwiperCore, { Navigation, Autoplay, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Autoplay, Pagination, Scrollbar, A11y])

import { Splide, SplideSlide } from '@splidejs/vue-splide'
// import '@splidejs/splide/dist/css/splide.min.css'
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css'

import moment from 'moment'

export default {
	name: 'Home',
	components: {
		Footer,
		Swiper,
		SwiperSlide,
		Splide,
		SplideSlide
	},
	setup(){
		const newsOptions = {
			rewind   : true,
			perPage: 3,
			perMove: 1,
			padding: '6rem',
			autoplay: true,
			gap    : '2rem',
			focus  : 'center',
			breakpoints: {
				640: {
					perPage: 2,
					gap    : '2rem',
					padding: '3rem',
				},
				480: {
					perPage: 1,
					gap    : '2rem',
					padding: '3rem',
				},
			},
		}

		const slider = ref([])
		const intro = ref([])
		const about = ref([])
		const news = ref([])

		onMounted(() => {
			axios.get('getSlider')
			.then((result) => {
				slider.value = result.data
				console.log('Slider : ', result.data)
			}).catch((err) => {
				console.log(err.response)
			})
			
			axios.get('getAbout2')
			.then((result) => {
				intro.value = result.data[0]
				console.log('Introduction : ', result.data[0])
			}).catch((err) => {
				console.log(err.response)
			})

			axios.get('getAbout')
			.then((result) => {
				about.value = result.data[0]
				console.log('About : ', result.data[0])
			}).catch((err) => {
				console.log(err.response)
			})

			axios.get('getNews')
			.then((result) => {
				news.value = result.data
				console.log('News : ', result.data)
			}).catch((err) => {
				console.log(err.response)
			})
		})

		function dateTime(value) {
			return moment(value).format("DD MMMM YYYY hh:mm:ss A");
		}

		return{
			newsOptions,
			slider,
			intro,
			about,
			news,
			dateTime,
		}
	}
}
</script>

<style lang="scss" scoped>
.home{
	.hero{
		display: flex;
		justify-content: space-between;
		align-items: center;

		&_title{
			order: 1;
			width: 30%;
			padding-left: 8%;
			z-index: 2;
			.box{
				background: white;
				margin-right: -100%;
				padding: 15% 5%;
				position: relative;
				z-index: 1;
				h2.title{
					font-weight: 700;
					color: $blue;
				}
				.btn__primary{
					margin-top: 30px;
				}
				&::before{
					content: "";
					top: -10px;
					left: 0;
					width: 100%;
					height: 100%;
					position: absolute;
					transform: rotate(7deg);
					background: rgba(255, 255, 255, 0.5);
					z-index: -1;
				}
			}
		}
		&_slider{
			order: 2;
			width: 70%;
			z-index: 1;
			.noSlider{
				display: none;
			}
			.slider{
				position: relative;
				.swiper-slide{
					height: 360px;
					img{
						width: 100%;
					}
				}
				.swiper-nav{
					&-next{
						bottom: 0;
						right: 0;
						border-left: 1px solid white;
					}
					&-prev{
						bottom: 0;
						right: 120px;
						border-right: 1px solid white;
					}
					position: absolute;
					width: 120px;
					height: 60px;
					background: rgba(255, 255, 255, 0.9);
					-webkit-backdrop-filter: blur(8px);
					backdrop-filter: blur(8px);
					background-color: rgba(185, 185, 185, 0.5);
					z-index: 5;
					display: flex;
					justify-content: center;
					align-items: center;
					transition: all ease 0.3s;
					&:hover{
						-webkit-backdrop-filter: blur(12px);
						backdrop-filter: blur(12px);
						// background-color: rgba(185, 185, 185, 0.8);
					}
				}
			}
		}
	}

	.intro{
		.title{
			text-align: center;
			color: $blue;
			font-weight: 700;
			text-transform: uppercase;
			margin: 40px 0 50px;
		}

		.image{
			position: relative;
			display: block;
			margin: 0 auto 40px;
			width: 80%;
			img{
				display: block;
				margin: 0 auto;
				width: 100%;
				height: auto;
			}
			&::before{
				content: "";
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				position: absolute;
				transform: rotate(8deg);
				background: rgba($color: #0285b3, $alpha: 0.2);
				z-index: -1;
			}
		}

		.content{
			color: $darkGrey;
			text-align: justify;
			font-size: 11pt;
			p{
				text-align: justify;
				font-size: 11pt;
			}
			ol{
				counter-reset: my-awesome-counter;
				list-style-type: none !important;
				li{
					font-size: 11pt;
					margin: 0 0 10px 10px;
					padding-left: 10px;
					text-align: justify;
					counter-increment: my-awesome-counter;
					position: relative;
					&::before{
						content: counter(my-awesome-counter);
						color: $blue;
						border: 1px solid $blue;
						background: white;
						font-size: 14pt;
						font-weight: bold;
						position: absolute;
						--size: 40px;
						left: calc(-1 * var(--size) - 0px);
						line-height: var(--size);
						width: var(--size);
						height: var(--size);
						top: 4px;
						text-align: center;
					}
				}
			}
		}
	}

	.about{
		background: $blue;
		position: relative;
		height: 340px;
		margin: 40px 0;
		.circle{
			position: absolute;
			top: 0;
			left: 0;
			width: 400px;
			height: auto;
			z-index: 2;
		}
		.dot_v{
			position: absolute;
			top: 20px;
			left: 20px;
			width: 90px;
			height: auto;
			z-index: 2;
		}
		.dot_v2{
			position: absolute;
			bottom: 20px;
			right: 20px;
			width: 90px;
			height: auto;
			z-index: 2;
		}
		.rect{
			position: absolute;
			bottom: 0;
			right: 0;
			width: 420px;
			height: auto;
			z-index: 1;
		}
	}
	.about_content{
		position: relative;
		margin: -380px 0 40px;
		.box{
			background: white;
			padding: 40px 60px;
			margin: 100px 0 40px;
			box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
			position: relative;
			z-index: 4;
			.title{
				text-align: center;
				text-transform: uppercase;
				color: $blue;
				font-weight: 700;
				margin: 0 0 20px 0;
			}
			p.desc{
				text-align: justify;
				color: $darkGrey;
			}
			.btn__primary{
				display: block;
				margin: 40px auto 0;
				width: 30%;
			}

			.dot_v_box{
				position: absolute;
				bottom: -50px;
				left: -50px;
				width: 90px;
				height: auto;
				z-index: -2;
			}
			.dot_h{
				position: absolute;
				bottom: -40px;
				right: -30px;
				width: auto;
				height: 90px;
				z-index: -2;
			}
		}
	}

	.news{
		margin: 0 0 40px 0;
		.title{
			text-align: center;
			color: $blue;
			font-weight: 700;
			text-transform: uppercase;
			margin: 40px 0 50px;
		}
		.splide{
			padding-bottom: 40px;
			height: 100%;
			.splide__slide{
				height: 100%;
				.card{
					border: none;
					border-radius: 0;
					// height: 300px;
					box-shadow: 0 3px 30px rgba(0, 0, 0, 0.05);
					.card-img-top{
						width: 100%;
						height: 180px;
						margin-bottom: 0;
						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
					.card-text{
						font-weight: 700;
						margin-bottom: 0.5rem;
					}
					.time{
						font-size: 8pt;
						color: $lightGrey;
						svg{
							width: 12px;
							height: 12px;
						}
					}
					.btn__primary{
						font-size: 10pt;
						float: right;
						padding: 10px 20px 10px 40px;
						svg{
							margin-left: 20px;
						}
					}
				}
			}
			.splide__arrows{
				.splide__arrow{
					background: $secondary !important;
					border-radius: 0 !important;
					&--next{
						right: 2rem !important;
					}
					svg{
						fill: $blue;
						path{
							fill: $blue;
							color: $blue;
						}
					}
				}
			}
		}
	}
	@media (max-width: 767.98px){
		.hero{
			flex-wrap: wrap;
			width: 100%;
			&_title{
				order: 2;
				width: 100%;
				padding-left: 0;
				.box{
					margin: -10px 5% 20px;
					padding: 5%;
					h2.title{
						text-align: center;
						font-size: 16pt;
					}
					.btn__primary{
						display: block;
						margin: 30px auto 0;
					}
					&::before{
						top: -5px;
						transform: rotate(5deg);
						// transform: scaleX(0.8);
					}
				}
			}
			&_slider{
				order: 1;
				width: 100%;
				.slider{
					.swiper-slide{
						height: 240px;
						img{
							height: 100%;
							width: 100%;
							object-fit: cover;
						}
					}
					.swiper-nav{
						&-next{
							top: 50%;
							right: 0;
							border-left: 0;
							transform: translateY(-50%);
						}
						&-prev{
							top: 50%;
							right: 0;
							left: 0;
							border-right: 0;
							transform: translateY(-50%);
						}
						width: 60px;
						height: 40px;

						img{
							width: 20px;
						}
					}
				}
			}
		}
		.intro{
			padding: 0 20px;
		}
		.about{
			.circle{
				width: 200px;
			}
			.dot_v{
				width: 50px;
			}
			.dot_v2{
				width: 50px;
			}
			.rect{
				width: 220px;
			}
		}
		.about_content{
			.box{
				padding: 30px 20px;
				.btn__primary{
					width: 80%;
				}
				.dot_v_box{
					bottom: -10px;
					left: -10px;
					width: 40px;
					height: auto;
				}
				.dot_h{
					bottom: -10px;
					right: -10px;
					width: auto;
					height: 40px;
				}
			}
		}
		.news{
			.splide{
				.splide__slide{
					height: 100% !important;
					.card{
						.btn__primary{
							margin-top: 10px;
						}
					}
				}
			}
		}
	}
}
</style>
