<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="menu">
                        <h3 class="title">Menu</h3>
                        <ul>
                            <li>
                                <router-link to="/">Beranda</router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'training' }">Pelatihan & Konsultasi</router-link>
                            </li>
                            <li>
                                <router-link to="/news">Berita</router-link>
                            </li>
                            <li>
                                <router-link to="/clients">Klien</router-link>
                            </li>
                            <li>
                                <router-link to="/about">Tentang Kami</router-link>
                            </li>
                            <li>
                                <router-link to="/contact">Hubungi Kami</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="contact">
                        <h3 class="title">Kontak Kami</h3>
                        <div class="socials">
                            <div class="item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                                </svg>
                            </div>
                            <div class="item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24.327" height="24.327" viewBox="0 0 24.327 24.327">
                                    <path id="Icon_awesome-facebook-square" data-name="Icon awesome-facebook-square" d="M21.721,2.25H2.607A2.607,2.607,0,0,0,0,4.857V23.971a2.607,2.607,0,0,0,2.607,2.607h7.453V18.307H6.638V14.414h3.421V11.447c0-3.375,2.009-5.239,5.086-5.239a20.725,20.725,0,0,1,3.015.263V9.783h-1.7a1.947,1.947,0,0,0-2.195,2.1v2.528H18l-.6,3.893H14.268v8.271h7.453a2.607,2.607,0,0,0,2.607-2.607V4.857A2.607,2.607,0,0,0,21.721,2.25Z" transform="translate(0 -2.25)" fill="#6b6b6b"/>
                                </svg>
                            </div>
                            <a href="mailto:delaprayoga18@gmail.com" target="_blank" class="item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30.409" height="24.327" viewBox="0 0 30.409 24.327">
                                    <path id="Icon_material-email" data-name="Icon material-email" d="M30.368,6H6.041A3.037,3.037,0,0,0,3.015,9.041L3,27.286a3.05,3.05,0,0,0,3.041,3.041H30.368a3.05,3.05,0,0,0,3.041-3.041V9.041A3.05,3.05,0,0,0,30.368,6Zm0,6.082L18.2,19.684l-12.164-7.6V9.041l12.164,7.6,12.164-7.6Z" transform="translate(-3 -6)" fill="#6b6b6b"/>
                                </svg>
                            </a>
                        </div>
                        <p class="loc">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                            </svg>
                            {{info.address}}
                        </p>
                        <p class="telp">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                            </svg>
                            {{info.no_telp1}} | {{info.no_telp2}}
                        </p>
                        <p class="website">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
                                <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
                            </svg>
                            www.bintangmas.com
                        </p>
                    </div>
                </div>
                <div class="col-12">
                    <hr>
                    <p class="text-center copyright">Copyright PT Bintang Mas Management Consulting 2021. All Right Reseved</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from 'axios'
export default {
    name: "Footer",
    setup(){
        const info = ref([])

        onMounted(() => {
			axios.get('getSetting')
			.then((result) => {
				console.log('Contact Info :', result.data)
				info.value = result.data
			}).catch((err) => {
				console.log(err.response)
			})
		})

        return{
            info,
        }
    }
}
</script>

<style lang="scss">
footer{
    background: #f6f6f6;
    padding: 40px 0 20px;
    .menu{
        .title{
            color: $blue;
            font-weight: 700;
            font-size: 16pt;
        }
        ul{
            margin-left: -30px;
            li{
                list-style-type: none;
                a{
                    color: $grey;
                    text-decoration: none;
                    font-weight: 600;
                    text-transform: capitalize;
                    border-left: 2px solid transparent;
                    transition: all ease-in-out 0.3s;
                    &:hover{
                        color: $blue;
                        padding-left: 10px;
                        border-left: 2px solid $blue;
                    }
                    &.router-link-exact-active{
                        color: $blue;
                        padding-left: 10px;
                        border-left: 2px solid $blue;
                    }
                }
            }
        }
    }
    .contact{
        .title{
            color: $blue;
            font-weight: 700;
            font-size: 16pt;
        }
        .socials{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0 0 10px 0;
            .item{
                margin-right: 10px;
                svg{
                    width: 28px;
                    height: 28px;
                    path{
                        fill: $grey;
                        transition: all ease-in-out 0.2s;
                    }
                    &:hover{
                        path{
                            fill: $blue;
                        }
                    }
                }
            }
        }
        p{
            color: $grey;
            svg{
                path{
                    fill: $grey;
                }
            }
        }
    }
    hr{
        background: $lightGrey;
    }
    .copyright{
        color: $grey;
        font-size: 10pt;
        margin-top: 30px;
    }
}
@media (max-width: 767.98px){
    footer{
        .menu{
            padding: 0 20px;
        }
        .contact{
            padding: 0 20px;
        }
    }
}
</style>